<template>
  <div class="bars-canvas-container">
    <!-- eslint-disable max-len vue/max-len -->
    <transition name="fade">
      <div class="bars-loader-spin" v-if="loading">
        <svg width="70px" height="70px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="preloader__svg" style="background: none;">
          <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="40" stroke-width="4" stroke-dasharray="62.83185307179586 62.83185307179586" transform="rotate(257.961 50 50)">
          </circle>
        </svg>
      </div>
    </transition>
    <canvas ref="canvas"></canvas>
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import { Bar, mixins } from 'vue-chartjs';
import Chart from 'chart.js';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    options: Object,
    loading: Boolean,
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    update() {
      this.renderChart(this.chartData, this.options);
    },
    renderChart(data, options) {
      Chart.defaults.global.defaultFontFamily = '"Avenir Next Cyr", Arial, Helvetica, sans-serif';
      if (this.$data._chart) this.$data._chart.destroy();
      if (!this.$refs.canvas) throw new Error('Please remove the <template></template> tags from your chart component. See https://vue-chartjs.org/guide/#vue-single-file-components');
      this.$data._chart = new Chart(
        this.$refs.canvas.getContext('2d'), {
          type: 'bar',
          data,
          options,
          plugins: this.$data._plugins,
        },
      );
    },
  },
};
</script>
<style lang="scss">
  .bars-canvas-container {
    transition: all .3s;
    position: relative;
  }
  .bars-loader-spin {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,.5);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }
</style>
